<template>
  <div class="popup_wrap bl_payment_pop" style="width:1000px; height: 550px;">
    <button class="layer_close" style="z-index: 800;" @click="close()">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.ONEX070G040.001') }}</h1> <!-- B/L 발행요청 -->
      <div class="content_box">
        <BLPaymentTarget
          ref="BLPaymentTarget"
          :bl-list="freightBillInfo.blList"
          @setReqBizCatCd="setReqBizCatCd"
          @setTaxInvoice="setTaxInvoice"
        >
        </BLPaymentTarget>
        <FreightBillDmdRadio
          ref="FBDmdRadio"
          v-show="isShow.dmdRadio && reqBizCatCd !== ''"
          :dmd-cat-cd="freightBillInfo.dmdCatCd"
          :ori-dmd-cat-cd="oriDmdCatCd"
          @child="getChildData"
        >
        </FreightBillDmdRadio>
        <FreightBillInfo
          ref="FBInfo"
          v-show="isShow.info"
          :dp-id="freightBillInfo.dpId"
          :bl-list="freightBillInfo.blList"
          @child="getChildData"
        ></FreightBillInfo>
        <FreightBillMethod
          ref="FBMethod"
          v-show="isShow.method"
          :err-arr="errArr.method"
          :clear-arr="clearArr.method"
          :dp-id="freightBillInfo.dpId"
          :btn-type="freightBillInfo.btnType"
          :validation-method="validationMethod"
        ></FreightBillMethod>
        <FreightBillCredit
          ref="FBCredit"
          v-if="reqBizCatCd !== '' && freightBillInfo.dmdCatCd === 'C'"
          :bl-list="freightBillInfo.blList"
          @child="getChildData"
        ></FreightBillCredit>
        <FreightBillTax
          ref="FBTax"
          v-show="isShow.taxTemplate"
          :err-arr="errArr.taxTemplate"
          :clear-arr="clearArr.taxTemplate"
          :dp-id="freightBillInfo.dpId"
          :btn-type="freightBillInfo.btnType"
          :validation-tax-template="validationTaxTemplate"
          :bl-list="freightBillInfo.blList"
          @setReqCont="setReqCont"
        ></FreightBillTax>
      </div>

      <div class="mt10 text_center">
        <a class="button blue lg" href="javascript:void(0)" @click="paymentChk()">{{ $t('msg.ONIM099P010.002') }}</a> <!-- 발행 -->
        <a class="button gray lg" href="javascript:void(0)" @click="close()">{{ $t('msg.ONIM099P010.003') }}</a> <!-- 취소 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
//세금계산서 모듈 관련
import CalcIssue from '@/api/rest/settle/calcIssue'
import BLPaymentTarget from '@/pages/trans/popup/BLPaymentTarget'
import FreightBillInfo from '@/components/common/FreightBillInfo'
import FreightBillMethod from '@/components/common/FreightBillMethod'
import FreightBillTax from '@/components/common/FreightBillTax'
import FreightBillDmdRadio from '@/components/common/FreightBillDmdRadio'
import FreightBillCredit from '@/components/common/FreightBillCredit'
import { rootComputed } from '@/store/helpers'
import blPayment from '@/api/rest/trans/blPayment'

let erp_popup = ''

export default {
  name: 'BLPaymentPop',
  components: {
    BLPaymentTarget,
    FreightBillInfo,
    FreightBillMethod,
    FreightBillTax,
    FreightBillDmdRadio,
    FreightBillCredit
  },
  props: {
    freightBillInfo: {
      type: Object,
      default: function () {
        return {
          blList: [],
          dpId: '',
          dmdCatCd: '',
          btnType: '',
          hasNotPaid: true,
          hasNotTaxInvoice: false,
          dpType: 'BLP'
        }
      }
    }
  },
  data: function () {
    return {
      reqBizCatCd: '',
      errArr: {
        info: [],
        method: [],
        taxTemplate: []
      },
      clearArr: {
        info: [],
        method: [],
        taxTemplate: []
      },
      isShow: { //세금계산서 화면 제어
        info: true, //결제정보
        method: true, //결제수단 선택
        taxTemplate: true, //세금계산서 공급 받는 자
        dmdRadio: true,
        credit: false
      },
      oriDmdCatCd: null,
      isInit: false,
      arrAddSrdBlNo: [],
      srd: {
        paidYn: '',
        addYn: '',
        billYn: ''
      },
      creditOut: 0
    }
  },
  computed: {
     ...rootComputed
  },
  watch: {
    freightBillInfo: {
      deep: true,
      handler () {
        this.getIsShow()
      }
    },
    'isShow.taxTemplate' (val) {
      // 세금계산서 항목이 숨겨지면 값을 초기화한다.
      if (!val) {
        this.$refs.FBTax.reset()
      }
    }
  },
  created () {
  },
  mounted: function () {
     setTimeout(() => {
      this.isInit = false
      this.getIsShow()
    }, 300)
  },
  methods: {
    getChildData (data) {
      switch (data.type) {
        case 'updateSelectDmdCatCd':
          this.freightBillInfo.dmdCatCd = data.payload.selectDmdCatCd
          this.getIsShow()
          break

        case 'creditOut':
          this.creditOut = data.payload.creditOut
          break
      }
    },
    async paymentChk () {
      if (this.reqBizCatCd === '') {
        const node = document.querySelector('.border_left_none')
        this.$ekmtcCommon.showErrorTooltip(node, this.$t('msg.CSBK100.147'))
        return false
      }

      if (this.freightBillInfo.dmdCatCd === 'C') {
        let blNo = []
        this.freightBillInfo.blList.forEach(bl => {
          blNo.push(bl.blNo)
         })
        await CalcIssue.getCreditLimitInfo(blNo)
        .then(response => {
          console.log(response)
          const info = response.data
          this.creditOut = info.amtInfo.outAmt
          console.log('info.amtInfo', info.amtInfo)
        })
        .catch(err => {
          console.log(err)
        })
        if (this.$ekmtcCommon.isNotEmpty(this.creditOut) && Number(this.creditOut) < 0) {
          this.msgAlert('외상한도 초과 또는 외상기한이 만료되었습니다.\n담당 영업사원 확인 또는 외상정리 후 신청 바랍니다.')
          return
        }
      }
      if (this.reqBizCatCd === '02' && this.$ekmtcCommon.isNotEmpty(this.arrAddSrdBlNo)) {
        if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.arrAddSrdBlNo + ' : ' + this.$t('서렌더피 KRW 40,000원이 추가됩니다.'), useConfirmBtn: true })) {
          return
        }
      }
      if (this.freightBillInfo.toOrderYn === 'Y') {
        //Consignee 가 TO ORDER 또는 TO THE ORDER 인 경우, WAYBILL 발행이 불가합니다. \r\n자세한 사항은 담당자에게 문의 바랍니다. (02-311-6114)
        const msg = this.$t('art.CSBL220.011')
        if (this.reqBizCatCd) {
          if (this.reqBizCatCd === '02') {
            const TH = this
            this.$ekmtcCommon.confirmCallBack(msg, () => { TH.payment() })
            return
          } else if (this.reqBizCatCd === '03') {
            this.msgAlert(msg)
            return
          }
        } else {
          this.msgAlert(this.$t('art.CSBL220.019')) //발행 구분을 다시 선택해주세요.
          return
        }
      }
      // WayBill 선택 시, 서렌더 피가 정상되었거나, 추가되었을 경우 제약. reqBizCatCd === '03'
      if (this.reqBizCatCd === '03') {
        if (this.srd.paidYn === 'Y') {
         // this.$ekmtcCommon.asyncAlertMessage({
         //  message: this.$t('SURRENDER FEE가 이미 정산되었습니다. 계산서 담당자 확인 바랍니다.')
         //  })
         // return
        }
        if (this.srd.billYn === 'Y') {
          this.$ekmtcCommon.asyncAlertMessage({
             message: this.$t('SURRENDER FEE가 포함된 계산서가 이미 발행되었으니 계산서 거절 후 신청 바랍니다.')
             })
          return
        }
        if (this.srd.addYn === 'Y') {
          this.$ekmtcCommon.asyncAlertMessage({
            message: this.$t('SURRENDER FEE가 인보이스에 추가되어 있습니다. 발급 담당자 확인 바랍니다.\n051-797-7400 (내선6)')
          })
          return
        }
      }
      this.payment()
    },
    //결제
    payment () {
      //세금계산서 컴포넌트별 데이터 emit
      let taxTemplate = this.$refs.FBTax.emitData('taxTemplate')
      let method = this.$refs.FBMethod.emitData('method')
      let info = this.$refs.FBInfo.emitData('info')
      let blReq = this.$refs.BLPaymentTarget.emitData('bl')

      // 세금계산서 발행 여부인 issStsCd를 BL 리스트에 세팅
      if (!this.isEmpty(info) && !this.isEmpty(info.blList)) {
        for (let i = 0; i < info.blList.length; i++) {
          info.blList[i].issStsCd = '01'

          if (!this.isEmpty(blReq) && !this.isEmpty(blReq.items)) {
            for (let j = 0; j < blReq.items.length; j++) {
              if (info.blList[i].blNo === blReq.items[j].blNo) {
                info.blList[i].issStsCd = blReq.items[j].issStsCd
                break
              }
            }
          }
        }
      }

      // 추가내용이 있는 경우 요청내용에 추가한다.
      if (taxTemplate && taxTemplate.reqAddCont) {
        blReq.reqCont = taxTemplate.reqAddCont + '\r\n' + blReq.reqCont
      }
      // console.log('>>>>> blReq', blReq)

      // if (this.validationMethod(method, 'payBtn') && this.validationTaxTemplate(taxTemplate, 'payBtn')) {
      if (this.validationInfoMethod(info, method, 'payBtn') && this.validationTaxTemplate(taxTemplate, 'payBtn')) {
        let req = this.parseReqData(taxTemplate, method, info, blReq)
        //미정산 금액 없을 때 발행요청만 처리 or 월말화주일 때 발행요청만 처리
        if (!this.freightBillInfo.hasNotPaid || this.freightBillInfo.dmdCatCd === 'M') {
          /*
          this.$refs.BLPaymentTarget.fnPayment(req.type).then((result) => {
            if (result.data === 2) {
              this.msgAlert('기존 요청 내용이 처리중입니다.')
            } else if (result.data === 3) {
              this.msgAlert('이미 승인되었습니다.')
            } else if (result.data === 1) {
              this.msgAlert(this.$t('art.CSBL220.017')) // 정상적으로 신청되었습니다.
            } else {
              this.msgAlert('저장시 오류가 발생하였습니다.')
            }
            this.close()
          })
          */
          this.bankingInfoAndBlRequest(req)
        } else {
          //결제수단(직접입금, 가상계좌, 즉시이체) 선택별 API 호출
          if (this.freightBillInfo.dmdCatCd !== 'C') {
            if (method.payType === 'D' && this.isShow.method) { //직접입금
              this.bankingInfoAndBlRequest(req)
            } else { //가상계좌, 즉시이체
              this.pay(req, method, info)
            }
          } else {
            this.bankingInfoAndBlRequest(req)
          }
        }
      }
    },
    bankingInfoAndBlRequest (req) {
      CalcIssue.bankingInfoAndBlRequest(req).then(response => {
         if (response.data.errCode === '2000') {
           this.msgAlert(this.$t('art.CSBL220.017')) // 정상적으로 신청되었습니다.
           this.$store.commit('settle/updateIsSettleSearchData', true)
           this.close()
          //서렌더 피 추가
           if (this.$ekmtcCommon.isNotEmpty(this.arrAddSrdBlNo) && this.reqBizCatCd === '02') {
               blPayment.createSrdFee(this.arrAddSrdBlNo).then(result => {
            })
          }
         } else if (response.data.errCode === '9002') {
           this.msgAlert(this.$t('art.CSBL220.009')) // 이미 발행 되었습니다.
         } else if (response.data.errCode === '9003') {
           this.msgAlert(this.$t('art.CSBL220.010')) // 이미 승인 되었습니다.
         } else if (response.data.errCode === '2001') { //waybill 자동
           //Sea Waybill 발행되었습니다.\n출력 하시겠습니까?
           this.$store.commit('settle/updateIsSettleSearchData', true)
           this.$ekmtcCommon.confirmCallBack('Sea Waybill 발행되었습니다.\n출력 하시겠습니까?', () => {
             this.$parent.$parent.$parent.openBlCopyPop()
             this.close()
           }, () => {
             this.close()
           })
         } else if (response.data.errCode === '9992') {
           this.msgAlert(this.$t('art.CSBL220.023')) // 이해당 건에 대하여 이미 처리 중에 있습니다.
         } else if (response.data.errCode === '9993') {
           this.msgAlert(this.$t('art.CSBL220.010')) // 이미 승인 되었습니다.
         } else {
           this.msgAlert(this.$t('art.CSBL220.015')) // 결제에 실패하였습니다.
         }
       }).catch(e => {
           this.msgAlert(this.$t('art.CSBL220.015')) // 결제에 실패하였습니다.
         console.log(e)
       })
    },
    pay (req, method, info) {
      CalcIssue.pay(req).then(response => {
        if (!this.isEmpty(response.data.acNo)) {
          if (req.type === 'N') {
            this.fnErpSave(response.data.acNo, response.data.erpReqNo, response.data.cnt, method, info)
          }
        }
        //this.bankingInfoAndBlRequest(req)
        if (response.data.errCode === '2000') {
          this.msgAlert(this.$t('art.CSBL220.017')) // 정상적으로 신청되었습니다.
          this.$store.commit('settle/updateIsSettleSearchData', true)
          this.close()
        } else {
          this.msgAlert(this.$t('art.CSBL220.015')) // 결제에 실패하였습니다.
          this.cancelPay(req.type, response.data.acNo)
        }
      }).catch(e => {
          this.msgAlert(this.$t('art.CSBL220.015')) // 결제에 실패하였습니다.
        //this.cancelPay(response.data.acNo)
        console.log(e)
      })
    },
    //전체데이터 셋팅
    parseReqData (taxTemplate, method, info, blReq) {
      let res = {}
      let vrAccNoList = []
      taxTemplate.reqCont = blReq.reqCont
      let blNoArr = info.blList.map((bl) => {
        return bl.blNo
      }).join(',')
      if (method.payType === 'D') {
        res = {
          reqBizCatCd: this.reqBizCatCd,
          asBlNo: blNoArr,
          blNo: blNoArr,
          payAcctList: method.depositInfoArr,
          taxTemplate: taxTemplate,
          type: method.payType,
          blList: info.blList,
          csbl220requestVO: {},
          amtSetList: [],
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          currencyCodeList: ['KRW', 'USD'],
          obrdDtList: [],
          corpNameList: [],
          csdo210vo: {},
          csbl220frVO: taxTemplate,
          csbl220accVO: {},
          file: taxTemplate.file,
          dpType: 'BLP',
          overseasFileList: this.isEmpty(method.overseasFileList) ? [] : method.overseasFileList,
          dmdCatCd: this.freightBillInfo.dmdCatCd,
          // 기존에 월말청구화주일 경우에만 creditYn이 Y인 것을 청구화주일 경우에도 creditYn이 Y가 되도록 변경
          creditYn: this.freightBillInfo.dmdCatCd === 'C' || this.freightBillInfo.dmdCatCd === 'M' ? 'Y' : 'N'
        }
      } else if (method.payType === 'V') {
        if (!this.isEmpty(method.vrAccount.krw)) {
          vrAccNoList.push(method.vrAccount.krw)
        }
        if (!this.isEmpty(method.vrAccount.usd)) {
          vrAccNoList.push(method.vrAccount.usd)
        }
        if (vrAccNoList.length === 0) {
          this.msgAlert('가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
          return false
        }
        res = {
          reqBizCatCd: this.reqBizCatCd,
          type: method.payType,
          asBlNo: blNoArr,
          blNo: blNoArr,
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          blList: info.blList,
          currencyCodeList: ['KRW', 'USD'],
          vrAcctNoList: vrAccNoList,
          obrdDtList: [],
          corpNameList: [],
          amtSetList: [],
          csdo210vo: {},
          taxTemplate: taxTemplate,
          csbl220frVO: taxTemplate,
          csbl220requestVO: {},
          csbl220accVO: {},
          file: taxTemplate.file,
          dpType: 'BLP',
          // 기존에 월말청구화주일 경우에만 creditYn이 Y인 것을 청구화주일 경우에도 creditYn이 Y가 되도록 변경
          creditYn: this.freightBillInfo.dmdCatCd === 'C' || this.freightBillInfo.dmdCatCd === 'M' ? 'Y' : 'N'
        }
      } else if (method.payType === 'N') {
        if (!this.isEmpty(method.vrAccount.krw)) {
          vrAccNoList.push(method.vrAccount.krw)
        }
        if (!this.isEmpty(method.vrAccount.usd)) {
          vrAccNoList.push(method.vrAccount.usd)
        }
        res = {
          reqBizCatCd: this.reqBizCatCd,
          type: method.payType,
          asBlNo: blNoArr,
          blNo: blNoArr,
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          blList: info.blList,
          currencyCodeList: ['KRW', 'USD'],
          vrAcctNoList: vrAccNoList,
          obrdDtList: [],
          corpNameList: [],
          amtSetList: [],
          csdo210vo: {},
          taxTemplate: taxTemplate,
          csbl220frVO: taxTemplate,
          csbl220requestVO: {},
          csbl220accVO: {},
          erpBankCode: method.bankLoginInfo.bnkAcct,
          erpBankName: '',
          file: taxTemplate.file,
          dpType: 'BLP',
          // 기존에 월말청구화주일 경우에만 creditYn이 Y인 것을 청구화주일 경우에도 creditYn이 Y가 되도록 변경
          creditYn: this.freightBillInfo.dmdCatCd === 'C' || this.freightBillInfo.dmdCatCd === 'M' ? 'Y' : 'N'
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(blReq)) {
        const mbpOfcNo = blReq.mbpOfcNo
        const cel1 = mbpOfcNo.length >= 3 ? mbpOfcNo.substring(0, 3) : mbpOfcNo
        const cel2 = mbpOfcNo.length >= 3 ? mbpOfcNo.replace(cel1, '') : ''
        res.csbl220requestVO = {
          reqPicNm: blReq.reqUsrNm,
          mbpBsnNo: cel1,
          mbpOfcNo: cel2,
          issPlcCd: blReq.issPlcCd,
          reqCont: blReq.reqCont,
          porDlyBlNo: blReq.polDlyBlNoArr.join(','),
          uploadFileList: blReq.uploadFileInfos,
          uploadjpTaxFileList: blReq.uploadjpTaxFileInfos
        }
      }

      const dmdCatCd = this.$refs.FBDmdRadio.selectDmdCatCd
      res.payType = method.payType
      res.dmdCatCd = dmdCatCd

      return res
    },
    fnErpSave (reqAcNo, erpKmtcNo, cnt, method, info) {
      let data = {
        userId: method.bankLoginInfo.bnkId,
        userPw: method.bankLoginInfo.bnkPswd,
        acNo: method.bankLoginInfo.bnkAcct,
        mnrcvBnkCd: '088',
        mnrcvAcNo: '26204000149',
        amt: info.krwTotal,
        inptDraweName: reqAcNo,
        AcnoMemo: '',
        erpKmtcNo: erpKmtcNo
      }
      CalcIssue.tenUnderTransfer(data).then(response => {
          let resp = response.data
          if (resp.rtnCode !== '0000') {
            this.msgAlert(resp.rtnMsg)
          } else {
            if (!this.isEmpty(resp.outRecDto) && !this.isEmpty(resp.outRecDto.rtnCode) && !this.isEmpty(resp.outRecDto.rtnCode)) {
              this.cancelPay('N', acNo)
            } else {
              //openPopup
              this.openErpPopup(cnt, response, data)
            }
          }
      }).catch(e => {
          this.msgAlert(this.$t('art.CSBL220.015')) // 결제에 실패하였습니다.
        this.cancelPay('N', reqAcNo)
        console.log(e)
      })
    },
    openErpPopup (cnt, response, data) {
      if (cnt === 1) {
        let url = 'https://cms.kbstar.com/quics?page=C066733'
        let name = this.$t('msg.SETT010T010.084') // 즉시이체
        let option = 'width = 880, height = 700, top = 100, left = 500, location = no,scrollbars=yes'
        erp_popup = window.open(url, name, option)
      } else {
        let url = 'https://cms.kbstar.com/quics?page=C102879'
        let name = '간편 이체'
        let option = 'width = 360, height = 460, top = 100, left = 500, location = no,scrollbars=yes'
        erp_popup = window.open(url, name, option)
      }
      this.chk_popup(response, data)
    },
    chk_popup (response, data) {
      if (typeof (erp_popup) === 'undefined' || erp_popup.closed) {
        this.erpResult(response, data)
      } else {
        setTimeout(() => {
          this.chk_popup(response, data)
        }, 3000)
      }
    },
    cancelPay (type, acNo) {
      CalcIssue.cancelPay({ type: type, acNo: acNo }).then(response => {
        console.log(response)
      }).catch(e => {
        console.log(e)
      })
    },
    erpResult (r, d) {
      let data = {
        userId: d.userId,
        userPw: d.userPw,
        erpregno: r.erpregno,
        erpAccNo: d.acNo
      }
      CalcIssue.tenUnderSel(data).then(response => {
        if (response.PrcssFnshYn === 'Y') {
          this.fnErpRegNoEdit(response.ERPRegiPrcssNo)
        }
      }).catch(e => {
        console.log(e)
      })
    },
    fnErpRegNoEdit (erpNo) {
      //
    },
    //세금계산서 결제수단 선택 밸리데이션
    validationMethod (method, type) {
    //   let errArr = []
    //   let clearArr = []
    //  if (this.isShow.method && this.freightBillInfo.dmdCatCd === 'R') {
    //     if (method.payType === 'D') {
    //       for (let row of method.depositInfoArr) {
    //         if (this.isEmpty(row.payCur)) {
    //           errArr.push({ id: 'fbt-payCur-' + row.rowIdx, msg: '화폐를 선택해주세요.' })
    //         } else {
    //           clearArr.push('fbt-payCur-' + row.rowIdx)
    //         }

    //         if (this.isEmpty(row.bank)) {
    //           errArr.push({ id: 'fbt-bank-' + row.rowIdx, msg: '은행을 선택해주세요.' })
    //         } else {
    //           clearArr.push('fbt-bank-' + row.rowIdx)
    //         }

    //         if (this.isEmpty(row.accNo)) {
    //           errArr.push({ id: 'fbt-accNo-' + row.rowIdx, msg: '계좌번호를 선택해주세요.' })
    //         } else {
    //           clearArr.push('fbt-accNo-' + row.rowIdx)
    //         }
    //         if (this.isEmpty(row.depositPnm)) {
    //           errArr.push({ id: 'fbt-depositPnm-' + row.rowIdx, msg: '입금자를 입력해주세요.' })
    //         } else {
    //           clearArr.push('fbt-depositPnm-' + row.rowIdx)
    //         }

    //         if (this.isEmpty(row.depositAmt)) {
    //           errArr.push({ id: 'fbt-depositAmt-' + row.rowIdx, msg: '금액을 입력해주세요.' })
    //         } else {
    //           clearArr.push('fbt-depositAmt-' + row.rowIdx)
    //         }

    //         if (this.$ekmtcCommon.isEmpty(row.depositDt)) {
    //           errArr.push({ id: 'fbt-depositDt-' + row.rowIdx, msg: '입금일을 입력해주세요.' })
    //         } else {
    //           clearArr.push('fbt-depositDt-' + row.rowIdx)
    //         }
    //       }
    //     } else if (method.payType === 'V') {
    //       //
    //     } else if (method.payType === 'N') {
    //       //
    //     }

    //     this.clearArr.method = clearArr
    //     if (errArr.length === 0) {
    //       return true
    //     } else {
    //       if (type === 'payBtn') {
    //         this.errArr.method = errArr
    //       }
    //       return false
    //     }
    //   } else {
    //     return true
    //   }
    return true
    },
    //세금계산서 결제정보, 결제수단 선택 밸리데이션
    validationInfoMethod (info, method, type) {
      let errArr = []
      let clearArr = []
     if (this.isShow.method && this.freightBillInfo.dmdCatCd === 'R') {
        if (method.payType === 'D') {
          for (let row of method.depositInfoArr) {
            if (this.isEmpty(row.payCur)) {
              errArr.push({ id: 'fbt-payCur-' + row.rowIdx, msg: this.$t('msg.CSBL220.017') }) // 화폐를 선택해주세요.
            } else {
              clearArr.push('fbt-payCur-' + row.rowIdx)
            }

            if (this.isEmpty(row.bank)) {
              errArr.push({ id: 'fbt-bank-' + row.rowIdx, msg: this.$t('msg.CSBL220.018') }) // 은행을 선택해주세요.
            } else {
              clearArr.push('fbt-bank-' + row.rowIdx)
            }

            if (this.isEmpty(row.accNo)) {
              errArr.push({ id: 'fbt-accNo-' + row.rowIdx, msg: this.$t('msg.CSBL220.019') }) // 계좌번호를 선택해주세요.
            } else {
              clearArr.push('fbt-accNo-' + row.rowIdx)
            }
            if (this.isEmpty(row.depositPnm)) {
              errArr.push({ id: 'fbt-depositPnm-' + row.rowIdx, msg: this.$t('msg.CSBL220.020') }) // 입금자를 입력해주세요.
            } else {
              clearArr.push('fbt-depositPnm-' + row.rowIdx)
            }

            if (this.isEmpty(row.depositAmt)) {
              errArr.push({ id: 'fbt-depositAmt-' + row.rowIdx, msg: this.$t('msg.CSBL220.021') }) // 금액을 입력해주세요.
            } else {
              clearArr.push('fbt-depositAmt-' + row.rowIdx)
            }

            if (this.$ekmtcCommon.isEmpty(row.depositDt)) {
              errArr.push({ id: 'fbt-depositDt-' + row.rowIdx, msg: this.$t('msg.CSBL220.022') }) // 입금일을 입력해주세요.
            } else {
              clearArr.push('fbt-depositDt-' + row.rowIdx)
            }
          }
        } else if (method.payType === 'V') {
          //
          let isExistKrw = false
          let isExistKrwUsd = false

          if (this.isEmpty(method.vrAccount.krw) && this.isEmpty(method.vrAccount.usd)) {
            this.msgAlert('가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
            return false
          }

          if (info.blList !== null && info.blList.length > 0) {
            for (let i = 0; i < info.blList.length; i++) {
              if (info.blList[i].currency === 'KRW') {
                isExistKrw = true
              } else if (info.blList[i].currency === 'KRW+USD') {
                isExistKrw = true
                isExistKrwUsd = true
              }
            }
          }

          if (isExistKrw && this.isEmpty(method.vrAccount.krw)) {
            this.msgAlert('KRW 가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
            return false
          }

          if (isExistKrwUsd && this.isEmpty(method.vrAccount.usd)) {
            this.msgAlert('USD 가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
            return false
          }
        } else if (method.payType === 'N') {
          //
          if (info.usdTotal > 0) {
            this.msgAlert('즉시이체는 KRW 결제만 가능합니다.')
            return false
          }
        }

        this.clearArr.method = clearArr
        if (errArr.length === 0) {
          return true
        } else {
          if (type === 'payBtn') {
            this.errArr.method = errArr
          }
          return false
        }
      }

      return true
    },
    validationTaxTemplate (taxTemplate, type) {
      let errArr = []
      let clearArr = []
      if (this.isShow.taxTemplate) {
        if (this.isEmpty(taxTemplate.rcivNm)) {
          errArr.push({ id: 'fbt-rcivNm', msg: this.$t('msg.ONIM050G020.017') }) // 상호를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivNm')
        }
        if (this.isEmpty(taxTemplate.rcivAddr)) {
          errArr.push({ id: 'fbt-rcivAddr', msg: this.$t('msg.ONIM050G020.017') }) //주소를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivAddr')
        }
        if (this.isEmpty(taxTemplate.rcivBzrgNo)) {
          errArr.push({ id: 'fbt-rcivBzrgNo', msg: this.$t('msg.ONIM050G020.035') }) // 사업자 번호를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivBzrgNo')
        }
        // if (this.isEmpty(taxTemplate.file)) {
        //   errArr.push({ id: 'fbt-file', msg: '사업자 등록증을 첨부해주세요.' })
        // } else {
        //   clearArr.push('fbt-file')
        // }
        if (this.isEmpty(taxTemplate.emlAddr)) {
          errArr.push({ id: 'fbt-emlAddr', msg: this.$t('msg.ONIM050G020.018') }) // email을 입력해주세요.
        } else {
          // let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
          let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (taxTemplate.emlAddr.match(regExp) == null) {
            errArr.push({ id: 'fbt-emlAddr', msg: this.$t('msg.REPORT_PDF.111') }) // 이메일 형식을 확인해 주세요.
          } else {
            clearArr.push('fbt-emlAddr')
          }
        }
        if (this.isEmpty(taxTemplate.rcivPicNm)) {
          errArr.push({ id: 'fbt-rcivPicNm', msg: this.$t('msg.CSBL270.015') }) // 담당자를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivPicNm')
        }
        if (this.isEmpty(taxTemplate.rcivCtfc)) {
          errArr.push({ id: 'fbt-rcivCtfc', msg: this.$t('msg.CSBL270.003') }) // 연락처를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivCtfc')
        }

        if (taxTemplate.acshIssKind === '02' && this.isEmpty(taxTemplate.acshIssDt)) {
          errArr.push({ id: 'fbt-acshIssDt', msg: this.$t('msg.ONIM050G020.033') }) // 계산서 발행일을 입력해주세요.
        } else {
          clearArr.push('fbt-acshIssDt')
        }

        if (this.$ekmtcCommon.isNotEmpty(taxTemplate.reqCont) && !this.$refs.FBTax.validationCheckByte('taxReqCont', 2000)) {
          errArr.push({ id: 'taxReqCont', msg: this.$t('msg.CSBK100.182') })
        } else {
          clearArr.push('taxReqCont')
        }

        this.clearArr.taxTemplate = clearArr
        if (errArr.length === 0) {
          return true
        } else {
          if (type === 'payBtn') {
            this.errArr.taxTemplate = errArr
          }
          return false
        }
      } else {
        return true
      }
    },
    close () {
      this.$emit('close')
      this.$refs.FBMethod.reset()
      this.$refs.FBTax.reset()
      this.$refs.BLPaymentTarget.reset()
      this.isInit = false
    },
    msgAlert (msg) {
       let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.SETT070G010.015'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    getIsShow () {
      if (!this.isInit) {
         this.oriDmdCatCd = JSON.parse(JSON.stringify(this.freightBillInfo.dmdCatCd))
         this.isInit = true
      }
      //영수,청구 등에 구분에 따라 보여줄 컴포넌트 제어(SA002I - ET_DMD_CAT_CD)
      let isShow = {
        info: true,
        method: true,
        taxTemplate: true,
        dmdRadio: true,
        credit: false
      }
      switch (this.freightBillInfo.btnType) {
        case 'FREIGHT':
          if (this.freightBillInfo.dmdCatCd !== 'R') { //영수화주가 아닐때
            if (!this.freightBillInfo.hasNotTaxInvoice) {
              isShow.taxTemplate = false
            }
          }
          break
        case 'TAX_INVOICE':
          //
          break
        case 'BL':
          if (this.freightBillInfo.dmdCatCd === 'R') { //영수화주
              if (this.freightBillInfo.hasNotTaxInvoice) {
                if (this.freightBillInfo.hasNotPaid) {
                  isShow.info = true
                  isShow.taxTemplate = true
                } else {
                  isShow.info = false
                  isShow.taxTemplate = false
                }
              } else {
                isShow.method = false
                isShow.info = false
                isShow.taxTemplate = false
              }
          } else if (this.freightBillInfo.dmdCatCd === 'C') { //청구화주
            isShow.method = false
            if (this.freightBillInfo.hasNotTaxInvoice) {
              isShow.info = true
              isShow.taxTemplate = true
            } else {
              isShow.info = false
              isShow.taxTemplate = false
            }
          } else if (this.freightBillInfo.dmdCatCd === 'M') { //월말화주
            isShow = {
              method: false,
              taxTemplate: false
            }
          }
          // 세금계산서 상태값 'PAID' 일때
          if (this.freightBillInfo.issStstus === 'Paid' && !this.freightBillInfo.hasNotPaid) {
            isShow = {
              method: false,
              taxTemplate: false
            }
          }

          break
        }

      if (this.freightBillInfo.btnType === 'FREIGHT') {
        isShow.dmdRadio = false
      } else {
        isShow.dmdRadio = true
      }
      this.isShow = isShow
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    setReqBizCatCd (reqBizCatCd) {
      console.log('@@@@ enter setReqBizCatCd')
      console.log(reqBizCatCd)
      this.reqBizCatCd = reqBizCatCd
      if (reqBizCatCd === '02' || reqBizCatCd === '03' || reqBizCatCd === '06') {
        this.srdChk(reqBizCatCd)
      }
    },
    setTaxInvoice (issStsCd) {
      // if (issStsCd === '01') {
      //   this.freightBillInfo.hasNotTaxInvoice = true
      // } else if (issStsCd === '02') {
      //   this.freightBillInfo.hasNotTaxInvoice = false
      // }
      // 세금계산서 미발행인 계산서가 존재할 경우 세금계산서 항목 오픈
      if (issStsCd === '01') {
        this.freightBillInfo.hasNotTaxInvoice = true
      }
    },
    setReqCont (reqCont, rcvProcCatCd) {
      // 개별발행, 합산발행 변경에 따른 요청내용 변경
      console.log('>>>>> BLPaymentPop : ' + reqCont + ', ' + rcvProcCatCd)
      this.$refs.BLPaymentTarget.setRcqCont(reqCont, rcvProcCatCd)
    },
    async srdChk (reqBizCatCd) {
      let arrSrdBlNo = []
      this.arrAddSrdBlNo = []
      this.freightBillInfo.blList.forEach(e => {
        arrSrdBlNo.push(e.blNo)
      })
      await blPayment.findSrdFeeStatus(arrSrdBlNo, reqBizCatCd)
          .then(result => {
            this.fnSrdMsg(result, reqBizCatCd)
          })
    },
    async fnSrdMsg (result, reqBizCatCd) {
      console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', result)
       for (let i = 0; i < result.data.length; i++) {
        if (reqBizCatCd === '02') {
          if (result.data[i].srdPaidYn === 'Y') {
//
          } else if (result.data[i].srdAddYn === 'Y') {
//
          } else if (result.data[i].srdWavYn === 'Y') {
//
          } else if (result.data[i].cnWavYn === 'Y') {
//
          } else if (result.data[i].srdWavAddYn === 'Y') {
//
          } else if (result.data[i].frtYn === 'N') {
//
          } else if (result.data[i].srdWavCstYn === 'Y') {
//
          } else {
             // await this.$ekmtcCommon.asyncAlertMessage({
             //  message: this.$t(result.data[i].blNo + ' : ' + this.$t('서렌더피 KRW 40,000원이 추가됩니다.'))
             // })
                this.arrAddSrdBlNo.push(result.data[i].blNo)
          }
        } else if (reqBizCatCd === '03' || reqBizCatCd === '06') {
          if (result.data[i].srdBillCd === '09') {
//
          } else if (result.data[i].srdBillCd === '10') {
            await this.$ekmtcCommon.asyncAlertMessage({
             message: this.$t('SURRENDER FEE가 포함된 계산서가 이미 발행되었으니 계산서 거절 후 신청 바랍니다.')
             })
             this.srd.billYn = 'Y'
          } else if (result.data[i].srdPaidYn === 'Y') {
            await this.$ekmtcCommon.asyncAlertMessage({
              message: this.$t('SURRENDER FEE가 이미 정산되었습니다. 계산서 담당자 확인 바랍니다.')
            })
            this.srd.paidYn = 'Y'
          } else if (result.data[i].srdAddYn === 'Y' && result.data[i].srdCancelYn !== 'Y') {
            await this.$ekmtcCommon.asyncAlertMessage({
             message: this.$t('SURRENDER FEE가 인보이스에 추가되어 있습니다. 발급 담당자 확인 바랍니다.\n051-797-7400 (내선6)')
             })
             this.srd.addYn = 'Y'
          }
        }
      }
    }
  }
}
</script>
